<script>
  import { base } from "$app/paths";
  import { siteTitle } from "$lib/constants";
  import Search from "$lib/components/Search.svelte";
  import { page } from "$app/stores";
  // import { theme, themeChanger } from "$lib/stores";

  $: pathname = $page.url.pathname;
</script>

<div class="header-container">
  <header class="header">
    <a data-sveltekit-prefetch href="{base}/"><h1>{siteTitle}</h1></a>
    {#if pathname !== "/"}
      <Search inHeader={true} />
    {/if}
    <span class="spacer_10rem" />
    <a class="about" href="{base}/about">About</a>
  </header>
</div>

<style>
  .header-container {
    /* max-width: var(--mid-bound); */
    display: flex;
    justify-content: center;
    border-bottom: 1px solid var(--bg-light);
  }
  .header {
    max-width: var(--mid-bound);
    padding: 1em;
    width: 100%;
    display: flex;
  }
  .header a:hover {
    text-decoration: underline;
  }
  .header h1 {
    font-size: 1rem;
    margin: 0.5rem;
    font-weight: 600;
    color: var(--text-accent);
  }

  .header a {
    color: var(--brand-color);
  }

  .spacer_10rem {
    flex: 1 1 10rem;
  }

  .about {
    font-size: 1rem;
    margin: 0.5rem;
    color: var(--text-accent);
  }
</style>
