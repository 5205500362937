<script>
  import "$lib/css/reset.css";
  import "$lib/css/global.css";
  import "$lib/css/syntax-highlight.css";
  import "$lib/client/app_insights";
  import { isSidebarOpen } from "$lib/stores";
  import Header from "$lib/components/Header.svelte";
</script>

<div class="wrapper overflow-hidden">
  <main class:show={$isSidebarOpen}>
    <Header inHeader={true} />
    <article>
      <slot />
    </article>
  </main>
</div>

<style>
  .wrapper {
    display: flex;
    overflow: hidden;
  }
  main {
    width: 100%;
  }

  article {
    margin: 1rem auto;
    max-width: var(--mid-bound);
  }
  .show {
    transform: translateX(18rem);
  }

  @media screen and (max-width: 800px) {
    .show {
      transform: translateX(14rem);
    }
  }
</style>
